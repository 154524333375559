<template>
  <div class="box_1">
    <div class="header">
      <div class="text" @click="goToMenuPage()">巴林右旗饲草商、兽医统计</div> 
    </div>

    <div class="content_box">
      <!-- 1 -->
      <div class="content_item_box">
        <div class="pai_hang">
          <div class="title"><span class="main_color font_number">饲草商 {{ siCaoShangList.length }}</span></div>

          <div class="list_header">
            <span>序号</span>
            <div class="name">姓名</div> 
            <div class="phone">电话</div> 
            <div class="ping_jia">评价</div> 
          </div>

          <div class="list_box">
            <div class="list_item" v-for="(item, index) in siCaoShangList" :key="index">
              <span class="font_number" v-if="index < 9">0{{ index+1 }}</span> 
              <span class="font_number" v-else>{{ index+1 }}</span> 

              <div class="name" @click="goToMuHuDetail(item.id)">{{ item.household_name }}</div> 

              <div class="phone"><span class="font_number fs_20">{{ formatPhone(item.phone_number) }}</span> </div> 

              <div class="ping_jia"> 
                <div class="star" v-for="(item, index) in item.star" :key="index">
                  <img :src="starIcon" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 

      <!-- 2 -->
      <div class="content_item_box">
        <div class="pai_hang">
          <div class="title"><span>兽医 {{ shouYiList.length }}</span></div>

          <div class="list_header">
            <span>序号</span>
            <div class="name">姓名</div> 
            <div class="phone">电话</div> 
            <div class="ping_jia">评价</div> 
          </div>

          <div class="list_box">
            <div class="list_item" v-for="(item, index) in shouYiList" :key="index">
              <span class="font_number" v-if="index < 9">0{{ index+1 }}</span> 
              <span class="font_number" v-else>{{ index+1 }}</span> 

              <div class="name" @click="goToMuHuDetail(item.id)">{{ item.household_name }}</div> 

              <div class="phone"><span class="font_number fs_20">{{ formatPhone(item.phone_number) }}</span> </div> 

              <div class="ping_jia"> 
                <div class="star" v-for="(item, index) in item.star" :key="index">
                  <img :src="starIcon" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
  import BaLinYouQiMap from '../maps/baLinYouQiMap.vue';

  export default {
    name: 'showPage1',
    components: { BaLinYouQiMap },
    data() {
      return {
        activeIndex: 0,
        starIcon: require('../../../../assets/images/ba-lin-you-qi/star.svg'),
        data1: {},
        data2: {},

        siCaoShangList: [
          {
            "household_name": "刘文才",
            "phone_number": "13948963103",
            "star": 5
          },
          {
            "household_name": "宝日参丹",
            "phone_number": "15174846292",
            "star": 5
          },
          {
            "household_name": "敖特根孟和",
            "phone_number": "15049942273",
            "star": 5
          },
          {
            "household_name": "个人",
            "phone_number": "13654864276",
            "star": 5
          },
          {
            "household_name": "金山",
            "phone_number": "15548920719",
            "star": 4
          },
          {
            "household_name": "莫日根图",
            "phone_number": "15049917939",
            "star": 4
          },
          {
            "household_name": "图雅",
            "phone_number": "15048693280",
            "star": 4
          },
          {
            "household_name": "哈斯其木格",
            "phone_number": "15048630664",
            "star": 4
          },
          {
            "household_name": "斯琴",
            "phone_number": "13789664462",
            "star": 4
          },
          {
            "household_name": "宝音和希格",
            "phone_number": "15648608585",
            "star": 4
          },
          {
            "household_name": "新其其格",
            "phone_number": "13847966542",
            "star": 4
          },
          {
            "household_name": "孟根阿古拉",
            "phone_number": "13754136312",
            "star": 4
          },
          {
            "household_name": "照日格图",
            "phone_number": "15147364390",
            "star": 4
          },
          {
            "household_name": "吴秀林",
            "phone_number": "13947687537",
            "star": 4
          },
          {
            "household_name": "德力格桑",
            "phone_number": "15149075174",
            "star": 4
          },
          {
            "household_name": "毕力根达来",
            "phone_number": "13948640207",
            "star": 3
          },
          {
            "household_name": "额尔灯毕力格",
            "phone_number": "13847647957",
            "star": 3
          },
          {
            "household_name": "吉仁巴雅尔",
            "phone_number": "15648684096",
            "star": 3
          },
          {
            "household_name": "李斌",
            "phone_number": "13947684440",
            "star": 3
          },
          {
            "household_name": "牟宝全",
            "phone_number": "15124982859",
            "star": 3
          },
          {
            "household_name": "朱广福",
            "phone_number": "13754168331"
          },
          {
            "household_name": "哈斯达来",
            "phone_number": "15047665693"
          },
          {
            "household_name": "宝音斯琴",
            "phone_number": "15849679411"
          },
          {
            "household_name": "德宝",
            "phone_number": "13789532215"
          },
          {
            "household_name": "斯日敖都",
            "phone_number": "15344153083"
          },
          {
            "household_name": "乌云参丹",
            "phone_number": "13948361412"
          },
          {
            "household_name": "那顺白音",
            "phone_number": "15004869151"
          },
          {
            "household_name": "布仁巴亚尔",
            "phone_number": "15047600256"
          },
          {
            "household_name": "额尔敦苏和",
            "phone_number": "15004863047"
          },
          {
            "household_name": "额尔敦巴特尔",
            "phone_number": "13614862661"
          },
          {
            "household_name": "朝鲁门",
            "phone_number": "13404871129"
          },
          {
            "household_name": "满都拉",
            "phone_number": "15147680082"
          },
          {
            "household_name": "乌兰斯其格",
            "phone_number": "15147626940"
          },
          {
            "household_name": "布和巴特尔",
            "phone_number": "15047637331"
          },
          {
            "household_name": "青满达",
            "phone_number": "15848985948"
          },
          {
            "household_name": "侯文良",
            "phone_number": "15648805337"
          }
        ],

        shouYiList: [
          {
            "household_name": "赵国",
            "phone_number": "13088417837",
            "star": 5
          },
          {
            "household_name": "宝音吉雅",
            "phone_number": "15247696797",
            "star": 5
          },
          {
            "household_name": "娜仁高娃",
            "phone_number": "15049940522",
            "star": 5
          },
          {
            "household_name": "哈斯朝鲁",
            "phone_number": "15047581891",
            "star": 5
          },
          {
            "household_name": "萨仁巴特尔",
            "phone_number": "13847617745",
            "star": 5
          },
          {
            "household_name": "刘伟",
            "phone_number": "15547981166",
            "star": 5
          },
          {
            "household_name": "阿拉木苏",
            "phone_number": "15947141397",
            "star": 4
          },
          {
            "household_name": "塞西亚拉图",
            "phone_number": "15149185329",
            "star": 4
          },
          {
            "household_name": "朝鲁门满都胡",
            "phone_number": "13948160174",
            "star": 4
          },
          {
            "household_name": "乌格德勒",
            "phone_number": "15004898034",
            "star": 4
          },
          {
            "household_name": "特木尔巴特尔",
            "phone_number": "15047671459",
            "star": 4
          },
          {
            "household_name": "包成玲",
            "phone_number": "13384818132",
            "star": 4
          },
          {
            "household_name": "萨其日拉图",
            "phone_number": "15847365000",
            "star": 4
          },
          {
            "household_name": "宝音得力根",
            "phone_number": "15149150102",
            "star": 4
          },
          {
            "household_name": "李景富",
            "phone_number": "13019591096",
            "star": 4
          },
          {
            "household_name": "白音德勒黑",
            "phone_number": "15148300963",
            "star": 3
          },
          {
            "household_name": "哈斯图雅",
            "phone_number": "13674864034",
            "star": 3
          },
          {
            "household_name": "额尔登图亚",
            "phone_number": "17547696977",
            "star": 3
          },
          {
            "household_name": "个人",
            "phone_number": "15848892118",
            "star": 3
          },
          {
            "household_name": "吉如和",
            "phone_number": "13847693152",
            "star": 3
          },
          {
            "household_name": "郑万龙",
            "phone_number": "15124911676",
            "star": 3
          },
          {
            "household_name": "孟和巴音",
            "phone_number": "18748037323"
          },
          {
            "household_name": "阿拉坦巴根",
            "phone_number": "13684771470"
          },
          {
            "household_name": "仁钦道尔吉",
            "phone_number": "13614862694"
          },
          {
            "household_name": "宝音满都胡",
            "phone_number": "15047255235"
          },
          {
            "household_name": "金龙",
            "phone_number": "15004869380"
          },
          {
            "household_name": "额尔敦敖其尔",
            "phone_number": "15104810976"
          },
          {
            "household_name": "乌日图那苏图",
            "phone_number": "15647679227"
          },
          {
            "household_name": "胡斯楞",
            "phone_number": "15560466318"
          },
          {
            "household_name": "毕力格巴特尔",
            "phone_number": "15849679309"
          },
          {
            "household_name": "乌力吉木仁",
            "phone_number": "15004866281"
          },
          {
            "household_name": "乌力吉桑",
            "phone_number": "14747692816"
          },
          {
            "household_name": "孟和巴亚尔",
            "phone_number": "18204883812"
          },
          {
            "household_name": "哈斯巴根",
            "phone_number": "17548759688"
          },
          {
            "household_name": "乌仁高娃",
            "phone_number": "13848067540"
          },
          {
            "household_name": "阿德亚",
            "phone_number": "15848977631"
          },
          {
            "household_name": "曹都毕力格",
            "phone_number": "15147368667"
          },
          {
            "household_name": "哈斯巴根",
            "phone_number": "13754062370"
          },
          {
            "household_name": "额尔敦宝力格",
            "phone_number": "15148130497"
          },
          {
            "household_name": "孟和德力格尔",
            "phone_number": "15148129214"
          },
          {
            "household_name": "布和套格套",
            "phone_number": "15124987246"
          },
          {
            "household_name": "张凤良",
            "phone_number": "18247645710"
          },
          {
            "household_name": "朝鲁门",
            "phone_number": "15147330393"
          },
          {
            "household_name": "巴图",
            "phone_number": "15614741506"
          },
          {
            "household_name": "屈金龙",
            "phone_number": "13154866477"
          },
          {
            "household_name": "鲍金兰",
            "phone_number": "15774836673"
          },
          {
            "household_name": "乌力吉布仁",
            "phone_number": "15148377793"
          },
          {
            "household_name": "蒙升家庭牧场",
            "phone_number": "18347632220"
          },
          {
            "household_name": "苏亚拉家庭牧场",
            "phone_number": "13789468552"
          },
          {
            "household_name": "布和朝鲁",
            "phone_number": "13848065977"
          },
          {
            "household_name": "特古斯巴特尔",
            "phone_number": "13948963171"
          },
          {
            "household_name": "恩克阿木拉",
            "phone_number": "15148371685"
          },
          {
            "household_name": "兴安",
            "phone_number": "15048615572"
          },
          {
            "household_name": "查干叁丹",
            "phone_number": "18547667691"
          },
          {
            "household_name": "娜仁通拉嘎",
            "phone_number": "18847610664"
          },
          {
            "household_name": "巴图巴雅尔",
            "phone_number": "13654766381"
          }
        ]
      }
    },
    mounted() {
      setTimeout(() => {
        this.initChart()
        this.getMuHuList()
      }, 100)
    },
    methods: {
      // 初始化图表
      initChart() {
        this.data1 = {
          title: '种植种类',
          data: [
            {"name": "玉米", "value": 320, "unit": '亩'},
            {"name": "甜玉米", "value": 460, "unit": '亩'},
            {"name": "小麦", "value": 650, "unit": '亩'},
            {"name": "葵花", "value": 1400, "unit": '亩'},
            {"name": "马铃薯", "value": 340, "unit": '亩'},
            {"name": "胡萝卜", "value": 220, "unit": '亩'},
            {"name": "香瓜", "value": 151, "unit": '亩'},
            {"name": "芸豆", "value": 23, "unit": '亩'}
          ]
        }

        this.data2 = {
          title: '养殖类',
          data: [
            {"name": "牛", "value": 134, "unit": '头'},
            {"name": "马", "value": 234, "unit": '头'},
            {"name": "绵羊", "value": 433, "unit": '头'},
            {"name": "山羊", "value": 245, "unit": '头'},
            {"name": "驴", "value": 34, "unit": '头'},
            {"name": "骆驼", "value": 546, "unit": '头'}
          ]
        }
      },

      // 获取牧户列表
      async getMuHuList() {
        let params = { village: '大板镇' }
        let res = await getMuHuList(params)

        this.muHuList = res.data.results.sort((a, b) => {
          return b.cattle_num - a.cattle_num
        })
      },

      // 跳转到牧户详情页
      goToMuHuDetail(id) {
        let muHuId = id

        this.$router.push({
          path: "/bigscreen/muHuPage" + muHuId
        })
      },

      clickTab(val) {
        this.activeIndex = val
        if (val == 0) {
          this.list = this.list1
        } else if (val == 1) {
          this.list = this.list2
        } else if (val == 2) {
          this.list = this.list3
        } else if (val == 3) {
          this.list = this.list4
        } else if (val == 4) {
          this.list = this.list5
        } else if (val == 5) {
          this.list = this.list6
        }
      },

      // 跳转到 人口统计 图表详情
      goToPersonStateDetail() {
        let title = `xxx村人口统计`

        this.$router.push({
          path: "/bigscreen/personState" + title
        })
      },

      // 跳转到 机械化统计 图表详情
      goToMashineStateDetail() {
        let title = `xxx村机械化统计`

        this.$router.push({
          path: "/bigscreen/mashineState" + title
        })
      },
      
      // 跳转到 menu 页面
      goToMenuPage() {
        this.$router.push({
          path: "/bigscreen/menuPage"
        })
      },

      formatPhone(val) {
        return val.substring(0, 3) + '****' + val.substring(7, 12)
      }
    }
  }
</script>

<style scoped lang="less">
  .activeColor { color: #2366fc !important; }

  .box_1 {
    width: 100%;
    height: 100%;
    padding: 10px;

    .header {
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;

      .text {
        width: 50%;
        height: 40px;
        font-size: 22px;
        font-weight: bold;
        color: #fff;
        // background-color: #2366fc;
        margin: 0 auto;
        cursor: pointer;
      }
    }

    .content_box {
      width: 100%;
      height: calc(100% - 50px);
      margin-top: 10px;
      // background-color: #2366fc;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding: 10px;

      .content_item_box { 
        width: 45%;
        height: 100%;
        // border: 1px solid #fff;
        padding: 10px;

        .pai_hang {
          width: 100%;
          height: 100%;

          .title { 
            width: 100%;
            height: 5%;
            text-align: center;
            position: relative;
            
            span {
              font-weight: bold;
              font-size: 18px;
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
            }
          }

          .list_header {
            width: 100%;
            height: 30px;
            line-height: 30px;
            font-size: 20px;
            padding: 0 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            color: #82fefe;

            span { width: 80px; }
            .name { width: 36%; }
            .phone { width: 25%; }
            .ping_jia { width: 25%; }
          }

          .list_box {
            width: 100%;
            height: calc(100% - 80px);
            overflow: auto;

            .list_item {
              width: 100%;
              height: 30px;
              line-height: 30px;
              font-size: 18px;
              padding: 0 20px;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              color: #fff;

              span { width: 80px;  }
              .name { width: 36%; cursor: pointer; text-align: left; }   
              .phone { width: 25%;  }
              .ping_jia { 
                width: 25%; 
                img {
                  float: left;
                }
              }
            }
          }
          .list_box::-webkit-scrollbar {
            width: 1px;
            height: 100%;
            background-color: #f5f5f5;
          }

          ::-webkit-scrollbar-thumb {                 // 表示纵向滚动块
            border-radius: 10px;
            background: #2366fc;
          }
        }
      }
    }

  }
</style>